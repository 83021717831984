<template>
	<div class="Insurance">
		<w-navTab titleText="人保寿险学生平安保险"></w-navTab>
		<div class="container">
			<div class="header">
				<div class="header-top">
					<img src="@/assets/img/text208.png" alt="" />
				</div>
				<div class="header-bottom">
					<img src="@/assets/img/text209.png" alt="" />
				</div>
			</div>
			<div class="middle">
				<div class="middle-img" @click="onPurchase(1)">
					<img src="@/assets/img/text210.png" alt="" />
				</div>
				<div class="middle-text">购买流程:点击购买链接，输入验证码:3360</div>
				<div class="middle-img" @click="onPurchase(2)">
					<img src="@/assets/img/text211.png" alt="" />
				</div>
				<div class="middle-text">购买流程:点击购买链接，输入验证码:3360</div>
			</div>
			<div class="body">
				<div class="body-header">
					<div class="body-header-title">
						<img src="@/assets/img/text212.png" alt="" />
					</div>
					<div class="body-header-img">
						<img src="@/assets/img/text213.png" alt="" />
					</div>
				</div>
				<div class="body-content">
					<div class="body-content-top">
						<img src="@/assets/img/text214.png" alt="" />
					</div>
					<div class="body-content-img">
						<img src="@/assets/img/text215.png" alt="" />
					</div>
				</div>
				<div class="body-footer">
					版权所有 © 中国人民保险（集团）股份有限公司 
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Insurance',
		data(){
			return{
				
			}
		},
		created(){
			
		},
		methods:{
			onPurchase(type){
				if(type == 1){
					window.location.href = 'https://m.picclife.cn/vxss-prod/vxss-policy-h5/product-template-new?combCode=VXSS-XPX-136-2081&utmType=2&utmSource=237&utmCode=MzEzNjIzMzM2MA%3D%3D&flag=1&forward_type=2&share_tip=2&noShare=1'
				}else{
					window.location.href = 'https://m.picclife.cn/vxss-prod/vxss-policy-h5/product-template-new?combCode=VXSS-XPX-136-1592&utmType=2&utmSource=237&utmCode=MzEzNjIzMzM2MA%3D%3D&flag=1&forward_type=2&share_tip=2&noShare=1'
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.Insurance{
		width: 100%;
		min-height: 100vh;
		background: #FFF4F0;
		padding-bottom: 22px;
		box-sizing: border-box;
		
		.container{
			width: 100%;
			.header{
				.header-top{
					position: relative;
					z-index: 2;
					width: 100%;
					height: 330px;
					
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.header-bottom{
					margin-top: -7px;
					width: 100%;
					height: 116px;
					
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
			.middle{
				margin-top: -21px;
				
				.middle-img{
					width: 350px;
					height: 124px;
					margin: 0 auto;
					
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.middle-text{
					margin: 12px auto 16px;
					text-align: center;
					font-size: 14px;
					line-height: 21px;
					color: #555;
				}
			}
			.body{
				.body-header{
					.body-header-title{
						width: 316px;
						height: 36px;
						margin: 12px auto 24px;
						
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.body-header-img{
						width: 350px;
						height: 181px;
						margin: 0 auto;
						
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
				.body-content{
					.body-content-top{
						width: 267px;
						height: 36px;
						margin: 28px auto 19px;
						
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.body-content-img{
						width: 100%;
						height: 210px;
						
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
				.body-footer{
					margin: 15px auto 0;
					text-align: center;
					width: 226px;
					font-size: 14px;
					line-height: 21px;
					text-align: center;
					color: #555;
				}
			}
		}
	}
</style>